import styles from "./tkTimeLine.module.scss";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { getTEPositionAnsWidth } from "./helpers";
import React, { useMemo } from "react";
import { TETooltipContent } from "./TETooltipContent";
import {
  SQL_DB_TK_Review_Response_AdjustedTimeLine,
  SQL_DB_TK_Review_Response_TETimeLine,
} from "../../../core/api/generated/conterra";
import { ResetSecondsDateString } from "../../../helpers/helpers";
import moment from "moment";

export const TimeLineItem = (props: {
  data:
    | SQL_DB_TK_Review_Response_TETimeLine
    | SQL_DB_TK_Review_Response_AdjustedTimeLine;
  originalTe?: SQL_DB_TK_Review_Response_TETimeLine;
  index: number;
}) => {
  const { data, originalTe } = props;
  const {
    costTypeCode,
    costTypeColor,
    startMinutes,
    finishMinutes,
    hours,
    approvedHours,
    start,
    finish,
  } = data;
  const { startFormatted, finishFormatted } = useMemo(() => {
    const startValue = ResetSecondsDateString(start);
    const finishValue = ResetSecondsDateString(finish);
    const startMoment = moment(startValue);
    const finishMoment = moment(finishValue);
    return {
      startFormatted: startMoment.format("LT"),
      finishFormatted: finishMoment.format("LT"),
    };
  }, [start, finish, approvedHours, hours]);

  const isApproved = !+hours || !!+Math.abs(approvedHours);
  const isLunch = costTypeCode === "LUNCH";
  const noWONoAllocation =
    !isLunch && originalTe?.wo === null && originalTe?.hasAllocation === false;
  const isNoGPS =
    !!originalTe?.event && originalTe.event.indexOf("NO_GPS") > -1;
  return (
    <div
      className={`${styles.TimeLineSegment} class-name`}
      style={getTEPositionAnsWidth(startMinutes, finishMinutes)}
    >
      <Tooltip
        style={{ maxWidth: 300 }}
        content={() => <TETooltipContent data={data} originalTe={originalTe} />}
        anchorElement="target"
        showCallout={false}
        parentTitle={true}
      >
        <div style={{ height: 25 }}>
          <div
            className={`${styles.TimeLineTE} ${
              isApproved
                ? styles.TimeLineTEApproved
                : styles.TimeLineTENotApproved
            }  class-name`}
            style={{ background: costTypeColor || "" }}
            title={`${startFormatted} - ${finishFormatted}`}
          >
            {noWONoAllocation && <div className={styles.TimeLineNoWO}></div>}
          </div>
          {isNoGPS && <div className={styles.TimeLineNoGPS}></div>}
        </div>
      </Tooltip>
    </div>
  );
};
