import {
  SQL_DB_TK_Review_Request,
  SQL_DB_TK_Review_Response,
  SQL_DB_TK_Review_Response_AdjustedTimeLine,
  SQL_DB_TK_Review_Response_DispatchInfo,
  SQL_DB_TK_Review_Response_TETimeLine,
  SQL_DB_TK_TimeCardsForCompare_Response_DispatchInfo,
  SQL_DB_TK_TimeCardsForCompare_Response_TETimeLine,
  SQL_DB_TK_TimeCardsForCompare_Response_TimeCards,
} from "../../core/api/generated/conterra";
import {
  ETCPerformAction,
  IProcessedTC,
  IProcessedTCOriginalTE,
  IProcessedTE,
} from "./interfaces";
import moment from "moment/moment";
import { formatTimeDiff, ResetSecondsDateString } from "../../helpers/helpers";
import api from "../../core/api/api";
import { isTKManager } from "../../core/tools/roles";

export const ROW_HEIGHT = 120;

export const isPossibleActions = (tc: SQL_DB_TK_Review_Response) =>
  tc.isPermitted &&
  (tc.reviewState == "P" || (isTKManager() && tc.reviewState !== "C"));

export const loadTimeCards = async (
  params: SQL_DB_TK_Review_Request,
  selectedTcId?: number | null
) => {
  const timeCards = await api.sql.dbTkReview(params);
  return timeCards.map((item) => {
    const procssedTC: IProcessedTC = {
      ...item,
      tcId: item.id,
      isPossibleActions: isPossibleActions(item),
      isSelected: selectedTcId === item.id,
    };
    return procssedTC;
  });
};

export const getProcessedDispatchInfo = (
  d:
    | SQL_DB_TK_Review_Response_DispatchInfo
    | SQL_DB_TK_TimeCardsForCompare_Response_DispatchInfo
) => ({
  clockOutBefore: d.clockOutBefore ? moment(d.clockOutBefore).format("LT") : "",
  clockInAfter: d.clockInAfter ? moment(d.clockInAfter).format("LT") : "",
});

export const getProcessedTE = (
  te:
    | SQL_DB_TK_Review_Response_AdjustedTimeLine
    | IProcessedTCOriginalTE
    | SQL_DB_TK_TimeCardsForCompare_Response_TETimeLine
): IProcessedTE => {
  const { start, finish, hours, approvedHours } = te;
  const startValue = ResetSecondsDateString(start);
  const finishValue = ResetSecondsDateString(finish);
  const startMoment = moment(startValue);
  const finishMoment = moment(finishValue);
  return {
    approvedDuration: formatTimeDiff(approvedHours * 3600),
    hoursDuration: formatTimeDiff(hours * 3600),
    startFormatted: startMoment.format("LT"),
    finishFormatted: finishMoment.format("LT"),
  };
};

export const filterByCTId = (
  te:
    | SQL_DB_TK_Review_Response_TETimeLine
    | SQL_DB_TK_Review_Response_AdjustedTimeLine
    | SQL_DB_TK_TimeCardsForCompare_Response_TimeCards
    | SQL_DB_TK_TimeCardsForCompare_Response_DispatchInfo
    | SQL_DB_TK_Review_Response_DispatchInfo,
  TCId: number
) => te.tcId === TCId;

export const PerformActionToSettings = {
  [ETCPerformAction.ChangeTime]: {
    title: "Change Time",
    iconClass: "mdi mdi-calendar-clock",
    themeColor: "error",
  },
  [ETCPerformAction.Reassign]: {
    title: "Reassign",
    iconClass: "mdi mdi-account-multiple-outline",
    themeColor: undefined,
  },
  [ETCPerformAction.Reject]: {
    title: "Reject",
    iconClass: "k-icon k-font-icon k-i-undo",
    themeColor: "error",
  },
  [ETCPerformAction.Complete]: {
    title: "Complete",
    iconClass: "mdi mdi-check-bold",
    themeColor: "success",
  },
  [ETCPerformAction.Confirm]: {
    title: "Confirm",
    iconClass: "mdi mdi-check-bold",
    themeColor: "success",
  },
  [ETCPerformAction.NoChanges]: {
    title: "Acknowledged",
    iconClass: "mdi mdi-check-bold",
    themeColor: "success",
  },
};
