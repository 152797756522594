import React, { useEffect, useState, useSyncExternalStore } from "react";
import { Modal } from "./Components/Common/Modal/Modal";
import {
  IntegrationWithDesktop,
  IntegrationWithDesktopContext,
  isMobile,
  settingsStorage,
} from "./helpers/settings";
import { authProvider } from "./core/api/api";
import Auth from "./Pages/Auth";
import Home from "./Pages/Home/Home";
import { CardsStack } from "./Components/Common/FXCard/Cards";
import { IconsContext } from "@progress/kendo-react-common";
import {
  addParamsToUrl,
  getParamsFromUrl,
  showSomeError,
} from "./helpers/helpers";
import BPTabsPage from "./Pages/BPTabs";
import { simpleObject } from "./helpers/interfaces";
import BPHistory from "./Components/BP/History";
import ReportRecordUsage from "./Pages/ReportRecordUsage/ReportRecordUsage";
import TCHistory from "./Components/TC/History";
import TCTabsPage from "./Pages/TCTabs";
import RelatedDocuments from "./Components/RelatedDocuments/RelatedDocuments";
import CheckListResults from "./Pages/CLM/ChecklistResults";
import MobileChecklistResults from "./Pages/CLM/ChecklistResults/MobileChecklistResults";
import CostRates from "./Components/PP/CostRates";
import LaborCosts from "./Components/PP/LaborCosts";
import WebIframe from "./Components/WebIframe";
import LocationCardView from "./Components/Map/LocationCardView";
import SiteCardView from "./Components/Map/SiteCardView";
import TKReviewNew from "./Pages/TKReview";
import TCAllocation from "./Components/TC/Allocation";
import BOMCardForm from "./Components/Cards/BomCard/Content";

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    showSomeError(error, "", info.componentStack);
    console.log(info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback;
    }

    return this.props.children;
  }
}

export const App = () => {
  const [isIntegrationWithDesktop, setIntegrationWithDesktopValue] =
    useState<boolean>(
      settingsStorage.getForCurrentUser(IntegrationWithDesktop) === "true"
    );

  const setIntegrationWithDesktop = (value: boolean) => {
    settingsStorage.setForCurrentUser(IntegrationWithDesktop, value.toString());
    setIntegrationWithDesktopValue(value);
  };

  const isAuthorized = useSyncExternalStore(
    authProvider.subscribeAuthorizationChange,
    authProvider.getIsAuthorized,
    authProvider.getIsAuthorized
  );

  useEffect(() => {
    document.title = authProvider.getInstanceId() || "FieldClix";
  }, [isAuthorized]);

  const renderPage = (folder: string, params: simpleObject) => {
    if (!folder) return null;
    if (folder === "BPHistory") {
      return <BPHistory woId={params.woId} />;
    } else if (folder === "RecordUsage") {
      return <ReportRecordUsage recordId={+params.recordId} />;
    } else if (folder === "TKTimeCard") {
      return <TCAllocation tcId={+params.tcId} isActive={true} />;
    } else if (folder === "TKTimeCardHistory") {
      return <TCHistory tcId={+params.tcId} isActive={true} />;
    } else if (folder === "TKTimeCardTabs") {
      return <TCTabsPage tcId={+params.tcId} />;
    } else if (folder === "Documents") {
      return (
        <RelatedDocuments
          isActive={true}
          SourceId={params.SourceId}
          isMobile={isMobile}
        />
      );
    } else if (folder === "CLPMReview") {
      let Component = isMobile ? MobileChecklistResults : CheckListResults;
      return (
        <Component
          isActive={true}
          isCLM={true}
          pageId={"CLPM"}
          buildPlanId={params.bpId}
        />
      );
    } else if (folder === "TKCostRates") {
      return <CostRates isActive={true} urlParams={params} />;
    } else if (folder === "TKLaborCost") {
      return <LaborCosts isActive={true} urlParams={params} />;
    } else if (folder === "LocationCard") {
      return <LocationCardView locationId={+params.Id} isModalCard={false} />;
    } else if (folder === "SiteCard") {
      return <SiteCardView siteId={+params.Id} isModalCard={false} />;
    } else if (folder === "TKReview") {
      return <TKReviewNew /*isActive={true}*/ />;
    } else if (folder === "BOM") {
      // todo open new cards in this window or in card management??? todo
      return <BOMCardForm bomId={params.SourceId} closeCard={() => {}} />;
    } else {
      let paramsString = addParamsToUrl(params, "");
      return (
        <WebIframe
          urlParams={paramsString}
          folder={folder}
          id={folder === "TKAuditCover" ? "TKAuditCoverFrame" : ""}
        />
      );
    }
  };

  const renderContent = () => {
    const params = getParamsFromUrl();
    const { folder, buildPlanId } = params;
    if (folder) return renderPage(folder, params);
    if (buildPlanId) {
      return (
        <BPTabsPage
          rowData={{ BPID: buildPlanId }}
          isActive={true}
          parentId="BPPage"
        />
      );
    }
    return <Home isIntegrationWithDesktop={isIntegrationWithDesktop} />;
  };

  return (
    <>
      <IconsContext.Provider value={{ type: "font" }}>
        <IntegrationWithDesktopContext.Provider
          value={{
            value: isIntegrationWithDesktop,
            setValue: setIntegrationWithDesktop,
          }}
        >
          <CardsStack />
          <Modal />
          <ErrorBoundary>
            {isAuthorized ? renderContent() : <Auth />}
          </ErrorBoundary>
        </IntegrationWithDesktopContext.Provider>
      </IconsContext.Provider>
    </>
  );
};
