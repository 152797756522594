import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import moment from "moment/moment";
import React, { useCallback, useContext } from "react";
import styles from "./tkTC.module.scss";
import { Chip } from "@progress/kendo-react-buttons";
import { IntegrationWithDesktopContext } from "../../../helpers/settings";
import { IProcessedTC } from "../interfaces";
import { formatTimeDiff } from "../../../core/tools/formats";
import { useBooleanState } from "../../../core/tools/Hooks";
import LoaderComponent from "../../../Components/Common/Loader";
import { ROW_HEIGHT } from "../helpers";
import { HorizontalTimeline } from "../../../Components/TC/HorizontalTimeline";
import { DispatchesChips } from "./DispatchesChips";
import { INewComboboxItem } from "../../../helpers/interfaces";
import { tabId } from "../../../Components/Tabs/interfaces";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { isTKManager } from "../../../core/tools/roles";
import { STATE_ID_TO_NAME } from "./helpers";
import { QuickChatHistory } from "./QuickChatHistory";
import TCActions from "./Actions";

export const TKListTimeCard = (props: {
  data: IProcessedTC;
  onToggleCheck: (isChecked: boolean, tcId: number) => void;
  checkBoxRefresher: number;
  isCheckedForAction: boolean;
  setDispatchFilter(dispatch: INewComboboxItem): void;
  onClick(initialTabId?: tabId): void;
  isCompareMode: boolean;
  onToggleCompareCheck(tc: IProcessedTC, value: boolean): void;
  isCheckedForComparing: boolean;
  isSelected: boolean;
  refreshList: () => void;
}) => {
  const {
    reviewState,
    id,
    date,
    reviewerName,
    // canReprocess,
    employeeName,
    isPossibleActions,
    hasPW,
    activeRequests,
    importantAuditResults,
    // IsSelected,
    isCrewLead,
    // isRelated,
    warnings,
    allocationStatus,
    auditedHours,
    clockedHours,
    approvedHours,
    lunchHours,
    firstClockIn,
    lastClockOut,
    adjustedTimeLine,
    reasonName,
    // canChangeAdjustments,
    notConfirmedAttestationCount,
    commentCount,
    teTimeLine,
    dispatchInfo,
  } = props.data;
  const {
    checkBoxRefresher,
    isCheckedForAction,
    isCompareMode,
    isCheckedForComparing,
    isSelected,
  } = props;
  const { value: isIntegrationWithDesktop } = useContext(
    IntegrationWithDesktopContext
  );
  const isLoading = useBooleanState(false);
  const toggleCompare = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      e.stopPropagation();
      props.onToggleCompareCheck(props.data, !isCheckedForComparing);
    },
    [props.onToggleCompareCheck, props.data, isCheckedForComparing]
  );

  const renderCheckBox = () => {
    if (isCompareMode) {
      return (
        <div className={styles.Check}>
          <span
            style={{
              color: isCheckedForComparing ? "var(--color-primary)" : undefined,
            }}
            className={`${styles.CompareCheckIcon} ${
              isCheckedForComparing
                ? "mdi mdi-checkbox-marked-circle-outline"
                : "mdi mdi-checkbox-blank-circle-outline"
            }`}
            onClick={toggleCompare}
          ></span>
        </div>
      );
    }
    return (
      <div className={styles.Check}>
        {isPossibleActions && (
          <Checkbox
            key={id + checkBoxRefresher}
            id={id + "_tc"}
            label={" "}
            size={"large"}
            value={isCheckedForAction}
            checked={isCheckedForAction}
            onChange={(event: CheckboxChangeEvent) =>
              props.onToggleCheck(event.value, id)
            }
            onClick={(e) => e.stopPropagation()}
          />
        )}
      </div>
    );
  };

  const renderEmployee = () => {
    return (
      <div className={styles.EmployeeContainer}>
        {/*{IsCrewLead && (
          <span
            title={"Crew Lead"}
            className={`mdi mdi-star ${styles.CrewLead}`}
          ></span>
        )}*/}
        <span
          className={styles.EmployeeName}
          style={isCrewLead ? { fontWeight: 600 } : {}}
          title={`${isCrewLead ? "Crew Lead: " : ""}${employeeName}`}
        >
          {employeeName}
        </span>
        {!!commentCount && <QuickChatHistory tcId={id} count={commentCount} />}
      </div>
    );
  };

  const renderDate = () => (
    <div className={styles.Date}>{moment(date).format("L")}</div>
  );

  const renderClockInOut = () => {
    return (
      <div className={styles.ClockInOut}>
        {!!firstClockIn && (
          <>
            <span
              className={"mdi mdi-circle-medium"}
              style={{ color: "green" }}
            ></span>
            <span title={"First Clock In"}>
              {moment(firstClockIn).format("LT")}
            </span>
          </>
        )}
        {!!lastClockOut && (
          <>
            <span
              className={"mdi mdi-circle-medium"}
              style={{ color: "red" }}
            ></span>
            <span title={"Last Clock Out"}>
              {moment(lastClockOut).format("LT")}
            </span>
          </>
        )}
      </div>
    );
  };

  const renderTimeFormula = () => {
    if (!clockedHours && !lunchHours && !auditedHours && !approvedHours)
      return null;
    return (
      <div className={styles.TimeFormula}>
        <span title={"Clocked"}>
          {formatTimeDiff((clockedHours || 0) * 3600)}
        </span>
        <span> - </span>
        <span title={"Lunch"} style={{ color: "orange" }}>
          {formatTimeDiff((lunchHours || 0) * 3600)}
        </span>
        <span> - </span>
        <span style={{ color: "red" }} title={"Audited"}>
          {formatTimeDiff((auditedHours || 0) * 3600)}
        </span>
        <span> = </span>
        <span style={{ color: "green" }} title={"Approved"}>
          {formatTimeDiff((approvedHours || 0) * 3600)}
        </span>
      </div>
    );
  };

  const renderAllocationStatus = () => {
    if (allocationStatus === "Done") {
      return (
        <Chip
          size={"medium"}
          themeColor={"success"}
          fillMode={"solid"}
          rounded={"medium"}
          text={"Allocation Done"}
          disabled={true}
          selected={true}
        />
      );
    }
    if (allocationStatus === "Pending") {
      return (
        <Chip
          size={"medium"}
          themeColor={"warning"}
          fillMode={"solid"}
          rounded={"medium"}
          text={"Pending Allocation"}
          disabled={true}
          selected={true}
        />
      );
    }
  };

  const renderChips = () => {
    return (
      <>
        {!!dispatchInfo.length && (
          <DispatchesChips
            dispatches={dispatchInfo}
            onSelectDispatch={props.setDispatchFilter}
          />
        )}
        {!!reasonName && (
          <Chip
            className={styles.ReasonChip}
            themeColor={"info"}
            size={"medium"}
            fillMode={"solid"}
            rounded={"medium"}
            text={`Reason: ${reasonName}`}
            disabled={true}
            selected={true}
          />
        )}
        {renderAllocationStatus()}
        {hasPW && (
          <Chip
            size={"medium"}
            fillMode={"solid"}
            rounded={"medium"}
            text={"PW"}
            disabled={true}
            themeColor={"info"}
            className={styles.PWChip}
            selected={true}
          />
        )}
        {!!importantAuditResults && (
          <Chip
            size={"medium"}
            themeColor={"error"}
            fillMode={"solid"}
            rounded={"medium"}
            selected={true}
            text={`${importantAuditResults} Important Audit${
              importantAuditResults > 1 ? "s" : ""
            }`}
            onClick={(e) => {
              e.syntheticEvent.stopPropagation();
              props.onClick("TCAudits");
            }}
          />
        )}

        {!!activeRequests && (
          <Chip
            size={"medium"}
            themeColor={"error"}
            fillMode={"solid"}
            rounded={"medium"}
            selected={true}
            text={`${activeRequests} Active Request${
              activeRequests > 1 ? "s" : ""
            }`}
            onClick={(e) => {
              e.syntheticEvent.stopPropagation();
              props.onClick("TCRequests");
            }}
          />
        )}

        {!!notConfirmedAttestationCount && (
          <Chip
            size={"medium"}
            themeColor={"error"}
            fillMode={"solid"}
            rounded={"medium"}
            selected={true}
            text={`${notConfirmedAttestationCount} Not Confirmed Attestation${
              notConfirmedAttestationCount > 1 ? "s" : ""
            }`}
            onClick={(e) => {
              e.syntheticEvent.stopPropagation();
              props.onClick("TCAttestations");
            }}
          />
        )}

        {!!warnings && (
          <Chip
            className={styles.WarningChip}
            size={"medium"}
            themeColor={"warning"}
            fillMode={"solid"}
            rounded={"medium"}
            text={warnings}
            disabled={true}
            selected={true}
          />
        )}
      </>
    );
  };

  if (isLoading.value) {
    return (
      <div
        style={{
          height: ROW_HEIGHT + "px",
          position: "relative",
        }}
      >
        <LoaderComponent />
      </div>
    );
  }

  return (
    <div
      className={`${styles.Container} ${
        isCompareMode && !isSelected ? styles.CompareMode : ""
      } ${isSelected ? styles.SelectedTC : ""}`}
      style={{ height: ROW_HEIGHT + "px" }}
      onClick={() => props.onClick()}
    >
      <div className={styles.Content}>
        <div className={styles.LeftCol}>
          <Tooltip anchorElement="target" showCallout={false}>
            <div className={styles.Row}>
              {!isSelected && renderCheckBox()}
              {renderDate()}
              {renderEmployee()}
            </div>
          </Tooltip>
          <div className={`${styles.Row} ${styles.ChipsRow}`}>
            {renderChips()}
          </div>
          {isTKManager() && (
            <div className={`${styles.Row} ${styles.Reviewer}`}>
              Reviewer: {reviewerName}
            </div>
          )}
        </div>

        <div className={styles.RightCol}>
          <Tooltip
            anchorElement="target"
            showCallout={false}
            parentTitle={true}
          >
            <div className={styles.Row}>
              {renderClockInOut()}
              {renderTimeFormula()}
              <div style={{ flex: 1 }}></div>
              <div className={styles.Actions}>
                <TCActions
                  tcId={id}
                  isIntegrationWithDesktop={isIntegrationWithDesktop}
                  refreshAfterAction={props.refreshList}
                  data={props.data}
                />
              </div>
              <div className={styles.Status}>
                <span>{STATE_ID_TO_NAME[reviewState]}</span>
              </div>
            </div>
          </Tooltip>
          <div style={{ maxWidth: 750 }}>
            <div className={`${styles.Row}`}>
              <HorizontalTimeline
                timeLine={teTimeLine}
                adjustedTimeLine={adjustedTimeLine}
                dispatches={dispatchInfo}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
