import { Component, JSX } from "react";

import styles from "./mileages.module.scss";
import { IDrawMileage, ITCMileage } from "./interfaces";
import { OpenRecord } from "../../../helpers/runscripts";

interface props {
  drawMileages: IDrawMileage[];

  refreshTC(): void;
}

class Mileages extends Component<props> {
  render() {
    const resultHTML: JSX.Element[] = [];
    const mileages = this.props.drawMileages;

    for (let i = 0; i < mileages.length; i++) {
      const item = mileages[i];
      if (!item.me) {
        resultHTML.push(
          <div
            key={i}
            className={styles.ME}
            style={{ height: item.CenterRows * 30 + "px" }}
          ></div>
        );
      } else {
        const next = mileages[i + 1]?.me;
        const prev = mileages[i - 1]?.me;
        const startKey = item.me.StartTEId + "_" + item.me.RowNumber;
        const centerKey =
          item.me.StartTEId +
          "_" +
          item.me.FinishTEId +
          "_" +
          item.me.RowNumber;
        const finishKey = item.me.RowNumber + "_" + item.me.FinishTEId;
        if (!prev)
          resultHTML.push(
            this.renderStartEndRow(item.me, 1, item.StartRows, startKey)
          );
        const text =
          item.me.Mileage === null
            ? "No Routes"
            : item.me.Mileage.toFixed(1) + " mi";
        const textStyle: React.CSSProperties = {
          top: item.StartRows * 15 * -1,
          bottom: item.FinishRows * 15 * -1,
        };
        resultHTML.push(
          <div
            key={centerKey}
            className={styles.ME}
            style={{ height: item.CenterRows * 30 + "px" }}
          >
            <span className={`${styles.MELine} ${styles.MEBefore}`}></span>
            {!!text && (
              <span className={styles.MEMiles} style={textStyle}>
                <span>{text}</span>
              </span>
            )}
          </div>
        );
        resultHTML.push(
          this.renderStartEndRow(
            item.me,
            !next ? 2 : 3,
            item.FinishRows,
            finishKey
          )
        );
      }
    }

    return <>{resultHTML}</>;
  }

  renderCircleBtn = (step: 1 | 2 | 3, data: ITCMileage) => {
    const refName =
      step === 1 ? data.StartLocationRefName : data.FinishLocationRefName;
    const id = step === 1 ? data.StartLocationId : data.FinishLocationId;
    return (
      <div
        className={styles.circleBtn}
        title={step === 1 ? data.StartTitle : data.FinishTitle}
        onClick={() => OpenRecord(refName, id)}
      ></div>
    );
  };

  renderStartEndRow = (
    data: ITCMileage,
    step: 1 | 2 | 3,
    rowsLength: number,
    key: string
  ) => {
    return (
      <div
        key={key}
        className={styles.ME}
        style={{ height: 30 * rowsLength + "px" }}
      >
        {(step === 1 || step === 3) && (
          <span className={`${styles.MELine} ${styles.MEStartBefore}`}>
            {this.renderCircleBtn(step, data)}
          </span>
        )}
        {(step === 2 || step === 3) && (
          <span className={`${styles.MELine} ${styles.MEEndBefore}`}>
            {this.renderCircleBtn(step, data)}
          </span>
        )}
      </div>
    );
  };
}

export default Mileages;
