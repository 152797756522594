import { IInitialTabSettings, tabId } from "../../Tabs/interfaces";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SQL_GetInfo_TKTimeCards_Response } from "../../../core/api/generated/conterra";
import { cardId, pageId } from "../../../helpers/interfaces";
import api from "../../../core/api/api";
import { showSomeError } from "../../../helpers/helpers";
import { GetTabId, TABS } from "../../Tabs/helpers";
import {
  IRenderToolbarProps,
  ITabSetting,
  ITabsToolbar,
  IToolbarDesktopLink,
} from "../interfaces";
import styles from "../tabsnew.module.scss";
import TabsToolbar from "../TabsToolbar";
import { useBooleanState } from "../../../core/tools/Hooks";

const TCTabsNew = (props: {
  parentId: pageId | cardId;
  // todo tcId fields from row data or TCId from old dashboard
  timeCardId?: number;

  initialTab?: tabId | IInitialTabSettings;
  nextTCId?: number;
  prevTCId?: number;
  refreshParent?(): void;
  selectTC?(tcId?: number): void;
}) => {
  const {
    timeCardId,
    initialTab,
    parentId,
    selectTC,
    nextTCId,
    prevTCId,
    refreshParent,
  } = props;
  const tcIdRef = useRef<number>();
  const [tcInfo, setTCInfo] = useState<SQL_GetInfo_TKTimeCards_Response>();
  const loading = useBooleanState(false);
  const tabsList: ITabSetting[] = useMemo(() => {
    const {
      activeRequestsCount,
      importantAuditResults,
      notConfirmedAttestationCount,
    } = tcInfo || {};
    return [
      { id: "TCTimelines" },
      { id: "TCAllocation" },
      {
        id: "TCRequests",
        counter: !!activeRequestsCount ? activeRequestsCount : "",
      },
      {
        id: "TCAudits",
        counter: !!importantAuditResults ? importantAuditResults : "",
      },
      {
        id: "TCAttestations",
        counter: !!notConfirmedAttestationCount
          ? notConfirmedAttestationCount
          : "",
      },
      { id: "TCHistory" },
    ];
  }, [tcInfo]);

  const [activeTabId, setActiveTabId] = useState<tabId>(
    GetTabId(initialTab || tabsList[0])
  );
  const initedTabsRef = useRef<tabId[]>([activeTabId]);

  const currentTcId = useMemo(() => {
    if (timeCardId !== tcIdRef.current) {
      tcIdRef.current = timeCardId;
    }
    return tcIdRef.current;
  }, [timeCardId]);

  useEffect(() => {
    if (currentTcId) loadTCInfo(currentTcId);
  }, [currentTcId]);

  useEffect(() => {
    if (initialTab) {
      setActiveTabAndProps(GetTabId(initialTab));
    }
  }, [initialTab]);

  const loadTCInfo = useCallback(
    async (tcId: number) => {
      try {
        loading.setTrue();
        const result = await api.sql.getInfoTkTimeCards({ tcId });
        setTCInfo(result[0]);
      } catch (e: any) {
        showSomeError(e);
      } finally {
        loading.setFalse();
      }
    },
    [/*loading, */ setTCInfo]
  );

  const setActiveTabAndProps = (Id: tabId) => {
    if (activeTabId === Id) return;
    if (initedTabsRef.current.indexOf(Id) === -1) {
      initedTabsRef.current.push(Id);
    }
    setActiveTabId(Id);
  };

  const desktopLinkSettings: IToolbarDesktopLink | undefined = useMemo(() => {
    const isCard = parentId === "TCCard";
    if (isCard || !currentTcId) return;
    return {
      refName: "TKTimeCards",
      objectId: currentTcId,
    };
  }, [currentTcId, parentId]);

  const renderToolbar = (specificSettings: IRenderToolbarProps) => {
    const commonSettings: ITabsToolbar = {
      isLoading: loading.value,
      tabsList: tabsList,
      activeTabId,
      onChangeTab: setActiveTabAndProps,
      desktopLink: desktopLinkSettings,
      nav: {
        handleNext: nextTCId && selectTC ? () => selectTC(nextTCId) : undefined,
        handlePrevious:
          prevTCId && selectTC ? () => selectTC(prevTCId) : undefined,
      },
      unSelect: () => selectTC?.(undefined),
    };
    return <TabsToolbar {...commonSettings} {...specificSettings} />;
  };

  return (
    <>
      {tabsList.map((tab) => {
        const tabId = GetTabId(tab);
        const isActiveTab = tabId === activeTabId;
        const isInited = initedTabsRef.current.indexOf(tabId) > -1;
        if (!isActiveTab && !isInited) return null;

        const { component: TabComponent } = TABS[tabId];
        const tabProps = {
          tcId: currentTcId,
          isActive: isActiveTab,
          pageId: parentId,
          refreshParent,
          renderToolbar,
        };

        return (
          <div
            key={tabId}
            className={`${styles.ContentWrap} ${
              !isActiveTab ? styles.HiddenTab : ""
            }`}
          >
            <TabComponent {...tabProps} />
          </div>
        );
      })}
    </>
  );
};

export default TCTabsNew;
