import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useBooleanState, useRefresher } from "../../core/tools/Hooks";
import { process, State } from "@progress/kendo-data-query";
import styles from "./tkReview.module.scss";
import TKReviewGrid from "./Grid";
import { CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { showSomeError } from "../../helpers/helpers";
import {
  EFiltersNames,
  IFilters,
  IProcessedTC,
  TLocalStorageKeys,
} from "./interfaces";
import TKListToolbar from "./Toolbar";
import { TKListTimeCard } from "./TimeCard";
import { SQL_DB_TK_Review_Response } from "../../core/api/generated/conterra";
import { INewComboboxItem, simpleObject } from "../../helpers/interfaces";
import { loadTimeCards } from "./helpers";
import { settingsStorage } from "../../helpers/settings";
import moment from "moment/moment";
import { ISO_DATE_FORMAT } from "../../core/tools/formats";
import { DateRangePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { TimeCardsActions } from "./Toolbar/TimeCardsActions";
import { CompareControl } from "./Toolbar/CompareControl";
import CardManagement from "../../Components/Cards/CardManagement";
import { tabId } from "../../Components/Tabs/interfaces";
import TCTabsNew from "../../Components/TabsNew/TCTabsNew";
import { CardsStackRef } from "../../Components/Common/FXCard/Cards";

const localStorageKeys: TLocalStorageKeys = {
  [EFiltersNames.RESOURCE]: "TKResource",
  [EFiltersNames.REVIEWER]: "TKReviewer",
  [EFiltersNames.PP]: "TKPayroll",
  [EFiltersNames.DISPATCH]: "TKDispatch",
  [EFiltersNames.REVIEW_STATE]: "TKReviewState",
  [EFiltersNames.DATE]: "TKDate",
  [EFiltersNames.DATE_TO]: "TKDateTo",
};

export default function TKReview() {
  const filtersValuesRef = useRef<IFilters>({});
  const filtersRefresher = useRefresher();
  const dispatchFilterRefresher = useRefresher();
  const [timeCards, setTimeCards] = useState<IProcessedTC[]>([]);
  const [localFilterState, setLocalFilterState] = useState<State | null>(null);
  const loading = useBooleanState(false);
  const toolbarExpand = useBooleanState(true);
  const checkAllRefresh = useRefresher();
  const [checkedTCs, setCheckedTCs] = useState<number[]>([]);
  const [tcsToCompare, setTcsToCompare] = useState<IProcessedTC[]>([]);
  const compareMode = useBooleanState(false);
  const listRefresher = useRefresher();
  const [selectedInfo, setSelectedInfo] = useState<{
    tc: IProcessedTC; // todo tcId
    initialTabId?: tabId;
  }>();
  const isASCSortRef = useRef(true);
  useEffect(() => {
    if (!selectedInfo) CardsStackRef.closeAllCards();
  }, [selectedInfo]);

  useEffect(() => {
    initFiltersFromStorage();
  }, []);

  useEffect(() => {
    loadData();
  }, [listRefresher.value]);

  useEffect(() => {
    if (!compareMode.value) setTcsToCompare([]);
  }, [compareMode.value]);

  const initFiltersFromStorage = useCallback(() => {
    for (let key in localStorageKeys) {
      const filterName = key as keyof TLocalStorageKeys;
      const valueStr = settingsStorage.getForCurrentUser(
        localStorageKeys[filterName]
      );
      if (
        valueStr &&
        (filterName === EFiltersNames.DATE ||
          filterName === EFiltersNames.DATE_TO)
      ) {
        filtersValuesRef.current[filterName] = moment(valueStr).toDate();
      } else {
        filtersValuesRef.current[filterName] = valueStr
          ? JSON.parse(valueStr)
          : null;
      }
    }
    filtersRefresher();
  }, [filtersValuesRef.current, filtersRefresher]);

  const clearDispatchFilter = useCallback(() => {
    filtersValuesRef.current[EFiltersNames.DISPATCH] = null;
    filtersRefresher();
    dispatchFilterRefresher();
  }, [filtersValuesRef.current, filtersRefresher, dispatchFilterRefresher]);

  const loadData = useCallback(async () => {
    try {
      loading.setTrue();
      const filters = filtersValuesRef.current;
      const params: simpleObject /*SQL_TK_Review_New_Request*/ = {};
      for (let filterId in filters) {
        const value = filters[filterId as keyof IFilters];
        if (!value) continue;
        const paramName = !(value instanceof Date)
          ? value.ServerParamName || filterId
          : filterId;
        params[paramName as keyof SQL_DB_TK_Review_Response] =
          value instanceof Date
            ? moment(value).format(ISO_DATE_FORMAT)
            : value.ServerParamValue !== undefined
            ? value.ServerParamValue
            : value.id;
      }
      // props.onSelectTC(null); // ??
      // console.log(params);
      const data = await loadTimeCards(params, selectedInfo?.tc?.tcId);
      /*if (selectedTCId) {
                                                                      const selectedTc = data.find((tc) => tc.isSelected);
                                                                      if (selectedTc) props.onSelectTC(selectedTc);
                                                                    }*/
      data.forEach((tc, index) => {
        if (index > 0) {
          const prevTC = data[index - 1];
          tc.prevTC = prevTC;
          prevTC.nextTC = tc;
        }
      });
      setTimeCards(data);
    } catch (e) {
      showSomeError(e);
    } finally {
      loading.setFalse();
    }
    // todo scroll to selected after refresh
  }, [loading, setTimeCards, filtersValuesRef.current, selectedInfo]);

  const setDispatchFilter = useCallback(
    (dispatch: INewComboboxItem) => {
      filtersValuesRef.current[EFiltersNames.DISPATCH] = dispatch;
      filtersRefresher();
      loadData();
    },
    [filtersValuesRef.current, filtersRefresher, loadData]
  );

  const onFilterChange = useCallback(
    (value: INewComboboxItem | null, filterName: keyof IFilters) => {
      if (value) {
        settingsStorage.setForCurrentUser(
          localStorageKeys[filterName],
          JSON.stringify(value)
        );
      } else {
        settingsStorage.removeForCurrentUser(localStorageKeys[filterName]);
      }
      // @ts-ignore
      filtersValuesRef.current[filterName] = value;
      if (filterName === EFiltersNames.RESOURCE) {
        clearDispatchFilter();
      }
      setTcsToCompare([]);
      setCheckedTCs([]);

      loadData();
    },
    [filtersValuesRef.current, clearDispatchFilter, loadData]
  );

  const onDateFilterChange = useCallback(
    (e: DateRangePickerChangeEvent) => {
      const { start, end } = e.value;
      const startLSKey = localStorageKeys[EFiltersNames.DATE];
      if (start) {
        settingsStorage.setForCurrentUser(
          startLSKey,
          moment(start).format(ISO_DATE_FORMAT)
        );
      } else {
        settingsStorage.removeForCurrentUser(startLSKey);
      }
      filtersValuesRef.current[EFiltersNames.DATE] = start;

      const endLSKey = localStorageKeys[EFiltersNames.DATE_TO];
      if (end) {
        settingsStorage.setForCurrentUser(
          endLSKey,
          moment(end).format(ISO_DATE_FORMAT)
        );
      } else {
        settingsStorage.removeForCurrentUser(endLSKey);
      }
      filtersValuesRef.current[EFiltersNames.DATE_TO] = end;
      clearDispatchFilter();
      loadData();
    },
    [filtersValuesRef.current, clearDispatchFilter, loadData]
  );

  const clearFilters = useCallback(() => {
    const filters = filtersValuesRef.current;
    for (let filterName in filters) {
      filters[filterName as keyof IFilters] = null;
      settingsStorage.removeForCurrentUser(
        localStorageKeys[filterName as EFiltersNames]
      );
    }
    dispatchFilterRefresher();
    filtersRefresher();
    loadData();
  }, [
    filtersValuesRef.current,
    dispatchFilterRefresher,
    filtersRefresher,
    loadData,
  ]);

  const filteredTimeCards: IProcessedTC[] = useMemo(() => {
    if (!localFilterState) return timeCards;
    return process(timeCards, localFilterState).data;
  }, [timeCards, localFilterState]);

  const processedTimeCards: IProcessedTC[] = useMemo(() => {
    return filteredTimeCards.map((i) => ({
      ...i,
    }));
  }, [filteredTimeCards]);

  const availableToActionsTimeCardsCount = useMemo(
    () =>
      filteredTimeCards.filter(({ isPossibleActions }) => isPossibleActions)
        .length,
    [filteredTimeCards]
  );

  const onToggleCheckTC = useCallback(
    (isChecked: boolean, tcId: number) => {
      setCheckedTCs(
        isChecked
          ? [...checkedTCs, tcId]
          : checkedTCs.filter((Id) => Id !== tcId)
      );
    },
    [setCheckedTCs, checkedTCs]
  );
  const handleSort = (asc: boolean) => {
    isASCSortRef.current = asc;
  };
  const onToggleCheckAll = useCallback(
    (event: CheckboxChangeEvent) => {
      setCheckedTCs(
        event.value
          ? filteredTimeCards
              .filter((tc) => tc.isPossibleActions)
              .map(({ tcId }) => tcId)
          : []
      );
      checkAllRefresh();
    },
    [setCheckedTCs, checkAllRefresh, filteredTimeCards]
  );

  const clearCheckedIds = useCallback(() => {
    setCheckedTCs([]);
    checkAllRefresh();
  }, [setCheckedTCs, checkAllRefresh]);

  const onToggleCompareCheckTC = useCallback(
    (tc: IProcessedTC) => {
      const state = tcsToCompare.findIndex(({ tcId }) => tcId === tc.tcId) > -1;
      setTcsToCompare(
        !state
          ? [...tcsToCompare, tc]
          : tcsToCompare.filter(({ tcId }) => tcId !== tc.tcId)
      );
    },
    [tcsToCompare, setTcsToCompare]
  );

  const renterTCItem = useCallback(
    (tc: IProcessedTC) => {
      const handleSelectTC = (initialTabId?: tabId) => {
        setSelectedInfo({ tc, initialTabId });
      };
      return (
        <TKListTimeCard
          key={tc.tcId}
          data={tc}
          onToggleCheck={onToggleCheckTC}
          checkBoxRefresher={checkAllRefresh.value}
          isCheckedForAction={
            checkedTCs.findIndex((tcId) => tcId === tc.tcId) > -1
          }
          setDispatchFilter={setDispatchFilter}
          onClick={handleSelectTC}
          onToggleCompareCheck={onToggleCompareCheckTC}
          isSelected={selectedInfo?.tc?.tcId === tc.tcId}
          isCheckedForComparing={
            tcsToCompare.findIndex(
              ({ tcId: compareId }) => compareId === tc.tcId
            ) > -1
          }
          refreshList={listRefresher}
          isCompareMode={compareMode.value}
        />
      );
    },
    [
      onToggleCheckTC,
      checkAllRefresh,
      checkedTCs,
      setDispatchFilter,
      setTcsToCompare,
      compareMode,
      compareMode.value,
      tcsToCompare,
      onToggleCompareCheckTC,
      checkAllRefresh.value,
    ]
  );

  return (
    <div className={styles.Container}>
      <div
        className={styles.ListContainer}
        style={{ flex: selectedInfo ? "0 0 auto" : 1 }}
      >
        <div style={selectedInfo ? { display: "none" } : {}}>
          <TKListToolbar
            onLocalStateChange={setLocalFilterState}
            refreshList={listRefresher}
            toolbarExpandState={toolbarExpand.value}
            onToggleToolbar={toolbarExpand.toggle}
            onClearFilters={clearFilters}
            filtersRefresher={filtersRefresher.value}
            dispatchFilterRefresher={dispatchFilterRefresher.value}
            onServerFilterChange={onFilterChange}
            onDateFilterChange={onDateFilterChange}
            filters={filtersValuesRef.current}
            onSortChange={handleSort}
            timecardsActions={
              <TimeCardsActions
                onToggleCheckAll={onToggleCheckAll}
                clearCheckedIds={clearCheckedIds}
                checkedTCS={checkedTCs}
                enableTCSCount={availableToActionsTimeCardsCount}
                refreshList={listRefresher}
              />
            }
            compare={
              <CompareControl
                compareMode={compareMode}
                tcsToCompare={tcsToCompare}
                compare={() => {
                  CardManagement.OpenTimeCardsComparisonCard({
                    timeCards: tcsToCompare,
                  });
                }}
              />
            }
          ></TKListToolbar>
        </div>
        <TKReviewGrid
          selectedTC={selectedInfo?.tc}
          toolbarExpandState={toolbarExpand.value}
          isLoading={loading.value}
          timeCards={processedTimeCards}
          renderTCItem={renterTCItem}
        />
      </div>
      <div
        className={styles.SelectedContainer}
        style={
          !selectedInfo
            ? { display: "none", position: "absolute", zIndex: -1 }
            : undefined
        }
      >
        <TCTabsNew
          parentId={"TKAuditCover"}
          timeCardId={selectedInfo?.tc?.tcId}
          initialTab={selectedInfo?.initialTabId}
          refreshParent={listRefresher}
          selectTC={(tcId?: number) => {
            const tc = processedTimeCards.find((x) => x.tcId === tcId);
            setSelectedInfo(tc && { tc });
          }}
          prevTCId={selectedInfo?.tc?.prevTC?.tcId}
          nextTCId={selectedInfo?.tc?.nextTC?.tcId}
        />
      </div>
    </div>
  );
}
